<template>
  <div class="page3">
    <img class="page3-img" src="../../assets/pages/obj_03.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'page3',
}
</script>

<style scoped lang="scss">
.page3 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .page3-img {
    height: 100%;
  }
}
</style>